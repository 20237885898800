
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class Contents1 extends Vue {
  private contents = [
    {
      question_image: ['/img/contents/WP16t1sahy0130200m01.png'],
      answer_image: '/img/contents/WP16t1sahy0130200t01.png',
      count: 3,
      results: [
        { collect: true, explanation: '/student/explanatory-video/test-1/1' },
        { collect: false, explanation: '/student/explanatory-video/test-1/1' },
        { collect: false, explanation: '/student/explanatory-video/test-1/1' },
      ],
    },
    {
      question_image: ['/img/contents/WP16t1sahy0130300m01.png'],
      answer_image: '/img/contents/WP16t1sahy0130300t01.png',
      count: 4,
      results: [
        { collect: true, explanation: '/student/explanatory-video/test-1/1' },
        { collect: false, explanation: '/student/explanatory-video/test-1/1' },
        { collect: true, explanation: '/student/explanatory-video/test-1/1' },
        { collect: false, explanation: '/student/explanatory-video/test-1/1' },
      ],
    },
  ]
}
